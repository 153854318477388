import { PropsWithChildren, useEffect, useState } from "react";
import { AuthProvider } from "react-oidc-context";
import { getOidcConfig } from "./oidcConfig";
import React from "react";

const isLocalhost = window.location.hostname === "localhost";

export function ConfiguredAuthProvider({
  children,
}: PropsWithChildren) {
  const [oidcConfig, setOidcConfig] = useState<Awaited<ReturnType<typeof getOidcConfig>>>();
 
  useEffect(() => {
    (async function () {
      if (isLocalhost) {
        setOidcConfig(undefined);
      } else {
        setOidcConfig(await getOidcConfig());
      }
    })();
  }, []);
 
  if (isLocalhost) return <>{children}</>; // Skip OIDC for localhost

  if (!oidcConfig) return <div>OidcConfig is not available yet...</div>;
 
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
}